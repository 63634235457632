import { Machine, assign } from "xstate";
import axios from "axios";
import { Auth } from "aws-amplify";
import { checkPhoneNumber } from "@/utils/international-phone";
import { alertService } from "@/main";
import { router } from "@/router";

export default Machine(
  {
    id: "CreateAccountForm",
    initial: "Q1",
    context: {
      error: {},
      organizationInfo: {
        organizationAbn: "",
        organizationRole: "",
        organizationName: "",
        contactFirstName: "",
        contactLastName: "",
        contactEmail: ""
      },
      accountInfo: {
        contactPhone: "",
        countryPhoneCode: "61",
        password: "",
        confirmPassword: "",
        termsAccepted: null,
        confirmCodeMobile: "",
        confirmCodeEmail: ""
      },
      registeredOrganization: {
        organizationName: "",
        organizationId: "",
        email: ""
      },
      registeredUser: {},
      signedInUser: {}
    },
    states: {
      Q1: {
        on: {
          SEARCH_ABN: "searchingASIC",
          RESET_COMPANY: {
            actions: "resetCompanyData"
          },
          CREATING_ORGANIZATION: "creatingOrganization",
          WELCOME: "welcome"
        }
      },
      searchingASIC: {
        entry: "resetCompanyData",
        invoke: {
          id: "searchAsic",
          src: "searchAsic",
          onDone: [
            {
              target: "Q1",
              actions: "assignCompanyData"
            }
          ],
          onError: {
            target: "Q1",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error getting company detail from ABN",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      creatingOrganization: {
        invoke: {
          id: "creatingOrganization",
          src: "creatingOrganization",
          onDone: {
            target: "Q2",
            actions: ["assignRegisteredOrganization"]
          },
          onError: {
            target: "Q1",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      Q2: {
        on: {
          CREATING_USER: "creatingUser"
        }
      },
      creatingUser: {
        invoke: {
          id: "creatingUser",
          src: "creatingUser",
          onDone: {
            target: "Q3",
            actions: ["assignRegisteredUser"]
          },
          onError: {
            target: "Q2",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      Q3: {
        on: {
          RESEND_SIGNUP: "resendingSignup",
          SUBMIT_CONFIRM_SIGNUP: "submittingConfirmSignup"
        }
      },
      resendingSignup: {
        invoke: {
          id: "resendingSignup",
          src: "resendingSignup",
          onDone: {
            target: "Q3",
            actions: [
              _context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "success",
                    message: "New code sent",
                    timer: 5
                  }
                })
            ]
          },
          onError: {
            target: "Q3",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      submittingConfirmSignup: {
        invoke: {
          id: "submittingConfirmSignup",
          src: "submittingConfirmSignup",
          onDone: {
            target: "signingIn"
          },
          onError: {
            target: "Q3",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      Q4: {
        on: {
          RESEND_SIGNIN: "signingIn",
          SUBMIT_CONFIRM_SIGNIN: "submittingConfirmSignIn"
        }
      },
      signingIn: {
        invoke: {
          id: "signingIn",
          src: "signingIn",
          onDone: {
            target: "Q4",
            actions: ["assignSignedInUser"]
          },
          onError: {
            target: "Q4",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      submittingConfirmSignIn: {
        invoke: {
          id: "submittingConfirmSignIn",
          src: "submittingConfirmSignIn",
          onDone: {
            target: "welcome",
            actions: ["submitLeadAndNavigateToWelcome"]
          },
          onError: {
            target: "Q4",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      welcome: {
        on: {
          ACCESS_PLATFORM: "accessingPlatform"
        }
      },
      accessingPlatform: {
        invoke: {
          id: "accessingPlatform",
          src: "accessingPlatform",
          onDone: {
            target: "done"
          },
          onError: {
            target: "welcome",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      error: {
        type: "final"
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      assignCompanyData: assign((context, event) => {
        const { data } = event;
        const response = data && data.length && data[0];

        return {
          organizationInfo: {
            ...context.organizationInfo,
            organizationName: response.name || "",
            organizationRole: "tenant"
          }
        };
      }),
      assignRegisteredOrganization: assign((context, event) => {
        return {
          registeredOrganization: {
            ...context.registeredOrganization,
            email: event.data?.email,
            organizationId: event.data?.organizationId,
            organizationName: event.data?.organizationName
          }
        };
      }),
      assignRegisteredUser: assign((context, event) => {
        return {
          registeredUser: {
            ...context.registeredUser,
            user: event.data?.user,
            userConfirmed: event.data?.userConfirmed,
            userSub: event.data?.userSub
          }
        };
      }),
      assignSignedInUser: assign((context, event) => {
        return {
          signedInUser: {
            ...context.signedInUser,
            user: event.data
          }
        };
      }),
      assignError: assign({
        error: (_context, event) => {
          return event.data?.response?.data?.errorMessage || event.data?.message;
        }
      }),
      submitLeadAndNavigateToWelcome: async (context, event) => {
        const lead = {
          type: "qualifiedLeadSubmit",
          email: context.organizationInfo.contactEmail,
          phone: checkPhoneNumber(
            `+${context.accountInfo.countryPhoneCode}${context.accountInfo.contactPhone}`
          ).phoneNumber,
          data: {
            firstname: context.organizationInfo.contactFirstName,
            lastname: context.organizationInfo.contactLastName,
            company: context.organizationInfo.organizationName,
            leadSource: context.leadSource,
            ...event.data.preQualification
          }
        };
        try {
          await axios
            .post(`${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/activity/submit`, lead)
            .then(response => response.data);
        } catch (e) {
          logger.error(e);
        }

        router.push({
          name: "Welcome"
        });
      }
    },
    services: {
      creatingOrganization: (context, _event) => {
        const data = {
          ...context.organizationInfo
        };
        // Make sure the users email is lowercase due cognito being case-sensitive
        data.contactEmail = data.contactEmail.toLowerCase();

        return axios
          .post(
            `${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/organization/register-fasttrack`,
            data
          )
          .then(response => response.data);
      },
      creatingUser: context => {
        // Merge contact phone to E.164 format
        const phoneNumber = checkPhoneNumber(
          `+${context.accountInfo.countryPhoneCode}${context.accountInfo.contactPhone}`
        ).phoneNumber;

        return Auth.signUp({
          username: context.registeredOrganization.email,
          password: context.accountInfo.password,
          attributes: {
            name: context.organizationInfo.contactFirstName,
            email: context.registeredOrganization.email,
            "custom:organizationId": context.registeredOrganization.organizationId,
            phone_number: phoneNumber
          }
        });
      },
      signingIn: (context, _event) => {
        return Auth.signIn(context.registeredOrganization.email, context.accountInfo.password);
      },
      resendingSignup: (context, _event) => {
        return Auth.resendSignUp(context.registeredOrganization.email);
      },
      submittingConfirmSignup: (context, _event) => {
        return Auth.confirmSignUp(
          context.registeredOrganization.email,
          context.accountInfo.confirmCodeEmail
        );
      },
      submittingConfirmSignIn: (context, _event) => {
        return Auth.confirmSignIn(
          context.signedInUser.user,
          context.accountInfo.confirmCodeMobile,
          "SMS_MFA"
        );
      },
      accessingPlatform: (_context, _event) => {
        router.push({
          name: "SignIn"
        });
      },
      searchAsic: (_context, event) => {
        const abn = event.data?.abn;

        return axios
          .get(`${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/asic/search?searchString=${abn}`)
          .then(response => response.data);
      }
    }
  }
);

import { assign } from "xstate";
import { watch } from "@vue/composition-api";
import get from "just-safe-get";
import { isDev, isLocal } from "@/utils/constants";
import { logger } from "@/utils/index";

export const enforceSSO = email => {
  const domain = email.split("@").pop();

  if (domain === "eguarantee.com.au") {
    // don't force eguarantee accounts using an alias to use SSO
    return !email.includes("+");
  }

  return process.env.VUE_APP_COGNITO_SUPPORTED_PROVIDERS.split(" ").includes(domain);
};

// watchState is used for logging state updates to the console...
export const watchState = (state, label = "") => {
  if (!state) return false;

  return watch(state, (newState, oldState) => {
    // Watch for state changes in dev...
    if (isDev || isLocal) {
      console.groupCollapsed(
        label ? `%c  ${label} Service:` : "State Service:",
        "color: #bada55",
        JSON.stringify(newState.value, null, 0)
      );

      // Log out the context and iterate over it's properties to format it nicely...
      console.group("Context");
      for (let [key, value] of Object.entries(newState.context)) {
        if (value && typeof value === "object") {
          console.group(`${key} (${typeof value})`);

          // To prevent console noise just log the first 3 items unfolded...
          if (Object.entries(value).length < 3) {
            for (let [k, v] of Object.entries(value)) {
              console.log(k, v);
            }
          } else {
            // Or the rest folded
            console.log(value);
          }
          console.groupEnd();
        } else {
          console.log(`${key}: ${value} (${typeof value})`);
        }
      }
      console.groupEnd();
      console.groupEnd();
    }
  });
};

// Some of the lambda error messages include error codes e.g [500]
// Helper method to remove the error codes...
export const removeErrorCode = msg => msg.replace(/^\[[0-9]+\] /gi, "");

// Helper method to get the graphQl error response...
export const mapGraphQlErrorResponse = (errors = []) => {
  return errors.map(error => removeErrorCode(error.message));
};

// Helper method to get the graphQl response data...
export const getGraphQlData = (event, keys) => {
  return get(event, `data.data.${keys}`);
};

// Actions
// Default actions we use in most state machines...
export const helperActions = {
  assignErrors: assign({
    errors: (context, event) => {
      return event?.data?.errors ? mapGraphQlErrorResponse(event?.data?.errors) : [event.data];
    }
  }),
  trackErrors: (context, event) =>
    mapGraphQlErrorResponse(event?.data?.errors).forEach(error => logger.error(error))
};

// Services
// Used to test graphQl responses from AppSync...
// export const mockGraphQlResponse = async ({ request, response, error }) => {
//   if (error) {
//     return Promise.reject(error);
//   }

//   // Mock response
//   return {
//     data: {
//       [request]: response,
//     },
//   };
// };
